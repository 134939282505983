import { DataTabList } from '~/types/data-array'

const SaaSDiscoverWorksTabList: DataTabList[] = [
  {
    name: 'Bakery platform',
  },
  {
    name: 'Rental solution',
  },
  {
    name: 'Service marketplace',
  },
]

export default SaaSDiscoverWorksTabList
