import { ServicesProcessMock } from '~/types/mock'

const SaaSProcess: ServicesProcessMock[] = [
  {
    num: '01',
    title: 'Research and discovery',
    descr:
      'This stage of SaaS product development services helps our team understand your business goals and target group and suggest the best ways to address them.',
  },
  {
    num: '02',
    title: 'Product design (UX and UI)',
    descr:
      'We create prototypes and UI for your future product. This helps us implement the user journey and build an intuitive custom app your users will love.',
  },
  {
    num: '03',
    title: 'Product development',
    descr:
      'Our mature software developers build your SaaS custom software for different cloud environments. Your platform will be secure, scalable, and maintainable.',
  },
  {
    num: '04',
    title: 'Optimization and QA',
    descr:
      'The next stage of SaaS development is quality assurance. We always put special effort into testing each SaaS site we create and optimizing the code.',
  },
  {
    num: '05',
    title: 'Deployment, monitoring, and support',
    descr:
      'We monitor your SaaS platform once it’s deployed and solve any issues that may arise. We can take care of your app and support it on a regular basis.',
  },
]

export default SaaSProcess
