import { HomeSpecification } from '~/types/mock'

const SaaSDomainExpertise: HomeSpecification[] = [
  {
    icon: 'sprite3DIconSmall_10',
    title: 'Online marketplaces',
    description:
      'Marketplaces offer products and services from many sellers at once. SaaS enables marketplaces’ ecosystems to be enriched by third-party products that improve the end-user experience.',
    link: '/case-studies/kids-activities-service-marketplace/',
  },
  {
    icon: 'sprite3DIconSmall_13',
    title: 'eCommerce',
    description:
      'SaaS eCommerce software comprises functionality, backups, hosting, and, also, technical support. With SaaS, you can significantly raise your eCommerce sales.',
    link: '/case-studies/custom-multi-vendor-marketplace-platform/',
  },
  {
    icon: 'sprite3DIconSmall_14',
    title: 'Travel',
    description:
      'SaaS apps we developed help make trips simple, fast, intuitive, and pleasant. So, you will get a solution your users like.',
    link: '/case-studies/travel-management-platform/',
  },
  {
    icon: 'sprite3DIconSmall_15',
    title: 'Automotive',
    description:
      'We provide reliable and scalable SaaS application development solutions that allow automotive manufacturers to stay ahead of the curve and customers easily buy vehicles.',
    link: '/services/automotive-software-development/',
  },
  {
    icon: 'sprite3DIconSmall_4',
    title: 'Insurance',
    description:
      'We create awesome SaaS projects for insurance businesses that offer a number of unique advantages that can drive your revenue results.',
    link: '/services/insurance-software-development/',
  },
  {
    icon: 'sprite3DIconSmall_11',
    title: 'Recruiting',
    description:
      'With Codica, you receive a profitable recruiting SaaS solution that makes the entire hiring process for recruiters more optimized and easy.',
    link: '/case-studies/recruitment-progressive-web-application/',
  },
  {
    icon: 'sprite3DIconSmall_16',
    title: 'Rental',
    description:
      'Rental apps we develop help you keep on top of your rental business. You will enhance bookings and increase revenue by letting users browse rentals and book online.',
    link: '/case-studies/accommodation-search-website-mvp/',
  },
  {
    icon: 'sprite3DIconSmall_17',
    title: 'Media',
    description:
      'With the growing number of media platforms, users need app helpers to manage various social media accounts. We will provide a robust SaaS media app to skyrocket your business.',
    link: '/case-studies/news-aggregator/',
  },
  {
    icon: 'sprite3DIconSmall_18',
    title: 'Healthcare',
    description:
      'We apply modern technologies for SaaS healthcare app development to deliver a seamless user experience. So, you will engage more people with a credible solution.',
    link: '/case-studies/dental-imaging-management-system/',
  },
]

export default SaaSDomainExpertise
