import { ExpertiseSectionMock } from '~/types/mock'

const SaaSTrustedBusinesses: ExpertiseSectionMock[] = [
  {
    icon: 'sprite3DIconSmall_7',
    title: 'Startups',
    description:
      'Designing a prototype, building a new SaaS MVP, or scaling an existing one, you need to act fast. And we got you covered! Launch your product and test ideas quickly and within the budget with our efficient SaaS software development services.',
  },
  {
    icon: 'sprite3DIconSmall_8',
    title: 'Small & medium businesses',
    description:
      'Codica’s programming team will help you move to the cloud with an efficient, secure, and scalable B2C or B2B SaaS product and start saving on hardware costs. We will also be happy to maintain, scale, and integrate your existing SaaS solution.',
  },
  {
    icon: 'sprite3DIconSmall_9',
    title: 'Enterprises',
    description:
      'Our expert developers are ready to help you with SaaS model work. As an experienced SaaS development company, we understand your challenges and will help you automate business processes and save operational costs with a custom SaaS product explicitly crafted for your company’s needs.',
  },
]

export default SaaSTrustedBusinesses
