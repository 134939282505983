// extracted by mini-css-extract-plugin
export var saasBusinessesSection = "r_b0";
export var saasClientQuotesSection = "r_cd";
export var saasDevExpertiseSection = "r_cb";
export var saasDiscoverWorksSection = "r_b7";
export var saasDomainExpertiseSection = "r_b2";
export var saasLineSection = "r_b1";
export var saasProcessSection = "r_b3";
export var saasRequirementSoftwareSection = "r_b6";
export var saasServicesSection = "r_bY";
export var saasSolutionSection = "r_cc";
export var saasSolutionsSection = "r_bZ";
export var saasSuccessfulSection = "r_b8";
export var saasTechStackSection = "r_b4";
export var saasTechStackSection__callToAction = "r_b5";
export var saasWhyShouldSection = "r_b9";