import { DevExpertiseCompanyMock } from '~/types/mock'

const SaaSDevExpertiseCompany: DevExpertiseCompanyMock[] = [
  {
    isThreeBlocks: true,
    title: '50+',
    text: 'projects successfully delivered',
  },
  {
    isThreeBlocks: true,
    title: '9+',
    text: 'years as a reliable SaaS software developer',
  },
  {
    isThreeBlocks: true,
    title: '60+',
    text: 'experienced SaaS developers on board',
  },
  {
    isThreeBlocks: true,
    title: '$56+',
    text: 'Mature designers who build excellent UI and UX that attract users',
    classNameText: 'maxWidth270',
  },
  {
    isThreeBlocks: true,
    title: '$56+',
    text: 'Project managers who know the keys to the successful delivery of any sized SaaS projects.',
    classNameText: 'maxWidth310',
  },
]

export default SaaSDevExpertiseCompany
