import { ExpertiseSectionMock } from '~/types/mock'

const SaaSHelpBuilding: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_34',
    title: 'Revenue increase',
    description: 'The SaaS market is expected to rise to $10 trillion by 2030.',
  },
  {
    icon: 'spriteIcon_15',
    title: 'Market development',
    description:
      'The cloud app market has grown unstopping year-over-year since 2013.',
  },
  {
    icon: 'spriteIcon_16',
    title: 'Value growth',
    description:
      'SaaS domain value is forecasted to enhance by over 2 x in major European markets by 2025.',
  },
]

export default SaaSHelpBuilding
