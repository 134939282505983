import { getImage } from 'gatsby-plugin-image'

import CompanyWhyHireBlock from '~/components/blocks/CompanyWhyHireBlock'
import FaqSection from '~/components/blocks/FAQ'
import HeadSection from '~/components/blocks/HeadSection'
import Testimonial from '~/components/blocks/TestimonialPost'
import CallToAction from '~/components/call-to-actions/CallToAction'
import Service3DIconCard from '~/components/cards/Service3DIconCard'
import ServicesIconSmallCard from '~/components/cards/ServicesIconSmallCard'
import ServicesIndustriesCard from '~/components/cards/ServicesIndustriesCard'
import ClientVideoQuotes from '~/components/items/ClientVideoQuotes'
import DiscoverWorksTabs from '~/components/items/DiscoverWorksTabs'
import MainLayout from '~/components/layouts/MainLayout'
import PrimeContactFormSection from '~/components/sections/PrimeContactFormSection'
import PrimeSectionServices from '~/components/sections/PrimeSectionServices'
import SubtractBlock from '~/components/shared/SubtractBlock'
import StickyNavigation from '~/components/sticky/StickyNavigation'
import Anchors from '~/mock/services/saas/Anchors'
import SaaSDevExpertiseCompany from '~/mock/services/saas/SaaSDevExpertiseCompany'
import SaaSDevServices from '~/mock/services/saas/SaaSDevServices'
import SaaSDiscoverWorksTabContent from '~/mock/services/saas/SaaSDiscoverWorksTabContent'
import SaaSDiscoverWorksTabList from '~/mock/services/saas/SaaSDiscoverWorksTabList'
import SaaSDomainExpertise from '~/mock/services/saas/SaaSDomainExpertise'
import SaaSFAQ from '~/mock/services/saas/SaaSFAQ'
import SaaSHelpBuilding from '~/mock/services/saas/SaaSHelpBuilding'
import SaaSProcess from '~/mock/services/saas/SaaSProcess'
import SaaSRequirements from '~/mock/services/saas/SaaSRequirements'
import SaaSSolutions from '~/mock/services/saas/SaaSSolutions'
import SaaSTrustedBusinesses from '~/mock/services/saas/SaaSTrustedBusinesses'
import SaaSWhyChoose from '~/mock/services/saas/SaaSWhyChoose'
import { fileToImageLikeData } from '~/utils'
import ServicesDevExpertiseCompany from '~/views/Services/components/ServicesDevExpertiseCompany'
import ServicesProcess from '~/views/Services/components/ServicesProcess'
import ServicesRequirementsSoftware from '~/views/Services/components/ServicesRequirementsSoftware'
import ServicesSectionBeforeTestimonials from '~/views/Services/components/ServicesSectionBeforeTestimonials'
import ServicesTechStackSection from '~/views/Services/components/ServicesTechStackSection'
import useSaasDevelopmentStaticQuery from '~/views/Services/SaasDevelopment/useSaasDevelopmentStaticQuery'

import * as containerStyles from './SaasDevelopment.module.scss'

const SaasDevelopment = () => {
  const query = useSaasDevelopmentStaticQuery()
  const saasTabContent = SaaSDiscoverWorksTabContent()

  const tailoredOne = getImage(fileToImageLikeData(query.tailoredOne))
  const tailoredTwo = getImage(fileToImageLikeData(query.tailoredTwo))
  const clientTradeaboat = getImage(fileToImageLikeData(query.clientTradeaboat))
  const quoteCodicaSerhiiPhoto = getImage(
    fileToImageLikeData(query.quoteCodicaSerhiiPhoto),
  )

  return (
    <MainLayout scrollTop>
      <PrimeSectionServices
        titleTop="SaaS Development"
        title="Services"
        description="Moving to the cloud? Become an industry leader with a cost-effective and highly scalable SaaS solution."
        breadcrumbSecondLevel="Services"
        breadcrumbSecondLevelPath="/services/"
        breadcrumbCurrent="SaaS Development Services"
        breadcrumbCurrentPath="/services/saas-development/"
      />

      <StickyNavigation anchors={Anchors} />

      <section className={containerStyles.saasServicesSection} id="services">
        <HeadSection
          position="center"
          title="Our SaaS application development services"
          description="Codica will help your business stand out from competitors with a unique, secure, and scalable SaaS solution. Whether you need to create a new cloud-based application for your business or manage an existing product, we are happy to share our expertise and passion for cloud solutions and help you solve any SaaS-related challenge."
          titleLarge
          descriptionLarge
        />
        <Service3DIconCard dataContent={SaaSDevServices} />
      </section>

      <CallToAction
        title="Do you have a great idea for a SaaS product?"
        subtitle="We will help to bring it to life."
        classNameForGA="servicesCTA_1"
        linkName="Talk to an expert"
        link="/contacts/"
      />

      <section className={containerStyles.saasSolutionsSection} id="solutions">
        <HeadSection
          position="center"
          title="We create remarkable SaaS solutions"
          description="With SaaS development services, we build high-quality single-tenant and multi-tenant SaaS platforms, applying the best industry practices."
          titleLarge
          descriptionLarge
        />
        <ServicesIconSmallCard isBorder dataContent={SaaSSolutions} />
      </section>

      <section
        id="business-type"
        className={containerStyles.saasBusinessesSection}
      >
        <HeadSection
          position="center"
          title="Trusted by businesses of any size"
          description="Having vast experience in SaaS development for startups and established enterprises, we know what challenges you address and how to solve them with technology. We are here to create a profitable SaaS product for you, whether a B2B, B2C, or C2C type of business."
          descriptionLarge
        />
        <ServicesIndustriesCard dataContent={SaaSTrustedBusinesses} />
      </section>

      <section className={containerStyles.saasLineSection} />

      <section
        id="industries"
        className={containerStyles.saasDomainExpertiseSection}
      >
        <HeadSection
          position="center"
          title="Our experience in many industries drives results for clients"
          description="Our experience of custom SaaS solutions development for particular domains has allowed us to grow in-house expertise which we gladly share with our clients. We provide consultations on every stage of the project development and know how to build a successful SaaS app following the best industry standards."
          descriptionLarge
        />
        <ServicesIndustriesCard dataContent={SaaSDomainExpertise} />
      </section>

      <section className={containerStyles.saasProcessSection} id="process">
        <HeadSection
          position="center"
          title="The SaaS product development process"
          description="We are fans of an agile methodology when providing SaaS application development services. The latest tech practices and tools help us satisfy all our clients' needs. Here, we want to share our stages for an effective SaaS project development cycle. This is the core of developing a quality SaaS app."
          titleLarge
          descriptionLarge
        />
        <ServicesProcess dataContent={SaaSProcess} />
        <SubtractBlock isTop />
      </section>

      <section className={containerStyles.saasTechStackSection} id="tech-stack">
        <HeadSection
          position="center"
          title="Perfect tech stack to develop a SaaS application"
          description="Over the years, we have picked a list of tools that help us build high-quality SaaS platforms. Together with our efficient development process and robust security measures, these tools enable us to keep the cost of SaaS software development within your expectations."
          descriptionLarge
        />
        <ServicesTechStackSection />

        <div className={containerStyles.saasTechStackSection__callToAction}>
          <CallToAction
            title="Looking for a reliable SaaS development agency?"
            subtitle="Tell us about your project."
            classNameForGA="servicesCTA_2"
            linkName="Get in touch"
            link="/contacts/"
          />
        </div>
      </section>

      <section
        id="requirements"
        className={containerStyles.saasRequirementSoftwareSection}
      >
        <HeadSection
          position="center"
          title="Modern requirements for advanced solutions"
          description="Based on our extensive experience in web application development for SaaS business, we build robust and high-quality software as a service platforms that meet all modern web standards:"
          descriptionLarge
        />
        <ServicesRequirementsSoftware dataContent={SaaSRequirements} />
      </section>

      <section className={containerStyles.saasDiscoverWorksSection} id="cases">
        <HeadSection position="left" title="Discover our works" />
        <DiscoverWorksTabs
          dataTabList={SaaSDiscoverWorksTabList}
          dataContent={saasTabContent}
        />
      </section>

      <section className={containerStyles.saasSuccessfulSection}>
        <HeadSection
          position="center"
          title="Successful SaaS app development with Codica"
          description="Having collaborated with numerous SaaS firms, we know what challenges you are looking to solve in your business operations and how to address them with technology."
          descriptionLarge
        />
        <ServicesSectionBeforeTestimonials
          imageOne={tailoredOne}
          imageTwo={tailoredTwo}
          content={[
            {
              text: 'Applying our extensive development skills and vast domain expertise, Codica experts create highly customizable SaaS solutions that are secure, fast-loading, scalable on-demand, and easily integrated with third-party services.',
            },
            {
              text: 'We know how to design, develop, and maintain the single-tenant and multi-tenant architecture of SaaS platforms with zero downtime. We apply our profound technical expertise, the best industry standards, and DevOps methodologies.',
            },
          ]}
        />
      </section>

      <section className={containerStyles.saasWhyShouldSection}>
        <HeadSection
          position="center"
          title="Why you should develop your SaaS application with Codica"
          description="Hiring a SaaS development company can be a big decision for the future of your business. Here are just some of the reasons why it is worth applying to Codica and how we can help you."
          titleLarge
          descriptionLarge
        />
        <CompanyWhyHireBlock dataContent={SaaSWhyChoose} />
      </section>

      <section className={containerStyles.saasDevExpertiseSection}>
        <HeadSection
          position="center"
          title="Deep SaaS development expertise"
          description="As we have vast experience creating successful SaaS projects, we will help you implement your business vision."
          descriptionLarge
        />

        <ServicesDevExpertiseCompany dataContent={SaaSDevExpertiseCompany} />

        <Testimonial
          image={quoteCodicaSerhiiPhoto}
          name="Serhii"
          position="Tech Lead & Rails expert at Codica"
          quote={[
            {
              text: '“When building SaaS applications, we focus on the needs and wants of end users. Our experience shows that mobility and security are the two major users’ concerns, be it your own employees or customers. Users simply want to be able to safely access the product anytime.',
            },
            {
              text: 'Being an expert SaaS development company, we understand this, and with the help of the latest technologies and modern web development approach, our team creates great SaaS apps that meet all end users’ demands.”',
            },
          ]}
        />
      </section>

      <section className={containerStyles.saasLineSection} />

      <section className={containerStyles.saasSolutionSection}>
        <HeadSection
          position="center"
          title="Why do you need a SaaS solution right now?"
          description="As more and more enterprises turn to subscription-based SaaS solutions, the demand for SaaS products is growing. These solutions are popular among businesses because they are cost-effective, scalable, accessible for users, and provide the ability to integrate with existing systems."
          titleLarge
          descriptionLarge
        />

        <ServicesIconSmallCard dataContent={SaaSHelpBuilding} />
      </section>

      <section className={containerStyles.saasClientQuotesSection}>
        <HeadSection
          position="center"
          title="Our clients say about Codica"
          description="At Codica, we value our clients' opinions and suggestions. We aim to realize all your requests for the solution during its development."
          descriptionLarge
        />

        <div className="container container-md">
          <ClientVideoQuotes
            name="Celso Prado"
            position="Digital General Manager at the Adventures Group"
            location="Australia"
            image={clientTradeaboat}
            quote={[
              {
                text: '“They are like my secret weapon. We are in verticals where market leaders have hundreds of in-house developers and multi-billion dollars in annual revenue. And despite all of that, our lean team, with the help from Codica, is growing its market share year after year.”',
              },
            ]}
            link="online-marketplace-for-boats"
            videoLink="https://www.youtube.com/embed/K9aOABDeKPI"
          />
        </div>
      </section>

      <section id="free-quote">
        <PrimeContactFormSection
          title="Let’s build an outstanding SaaS product together!"
          formInfoClassName="formInfo__services"
          customSalesInfo
          customSalesInfoStepFour="4. Our experts will analyze your requirements and suggest the best ways to bring your idea to life."
        />
      </section>

      <section id="faq">
        <FaqSection title="FAQ" dataContent={SaaSFAQ} />
      </section>
    </MainLayout>
  )
}

export default SaasDevelopment
