import { BaseMock } from '~/types/mock'

const SaaSWhyChoose: BaseMock[] = [
  {
    title: 'Save costs and launch quickly',
    description:
      'We have built an efficient rapid SaaS development process, allowing us to create and deliver your platform on time and on budget.',
  },
  {
    title: 'Extensive cloud expertise',
    description:
      'Having built multiple SaaS products, our SaaS application development company has accumulated profound market and industry knowledge.',
  },
  {
    title: 'Full control over product development',
    description:
      'Our SaaS web application development process is fully transparent, which is guaranteed by timely reports and excellent communication.',
  },
  {
    title: 'Secure and scalable solution',
    description:
      'Applying the best DevOps practices and cutting edge technologies, we ensure the high scalability and security of your SaaS application.',
  },
  {
    title: 'Enthusiastic team that cares',
    description:
      'We are proud of our result-oriented team that values communication and flexibility and focuses on high-value relationships that last.',
  },
  {
    title: 'Support after launch',
    description:
      'Our work doesn’t stop when your SaaS product goes live. Our reliable support team will ensure your SaaS app runs smoothly.',
  },
]

export default SaaSWhyChoose
