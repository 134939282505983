import { ExpertiseSectionMock } from '~/types/mock'

const SaaSSolutions: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_5',
    title: 'CRM systems',
    description:
      'Our robust customer relationship management systems will streamline sales pipelines and improve the customer experience.',
  },
  {
    icon: 'spriteIcon_6',
    title: 'ERP systems',
    description:
      'Moving enterprise resource planning systems to the cloud enables businesses to work more effectively. So, you will see a quicker return on your investment.',
  },
  {
    icon: 'spriteIcon_7',
    title: 'CMS systems',
    description:
      'SaaS content management systems are an amazing way to edit and customize your solution simply.',
  },
  {
    icon: 'spriteIcon_8',
    title: 'Inventory management',
    description:
      'Our inventory management software enables managing inventory, expanding your sales, and staying updated on your stock.',
  },
  {
    icon: 'spriteIcon_9',
    title: 'E-commerce',
    description:
      'SaaS eCommerce platforms include the benefits of on-premise and open-source solutions without any of their cons.',
  },
  {
    icon: 'spriteIcon_10',
    title: 'Payroll processing',
    description:
      'Our payroll processing solutions are simple and streamlined, handling tax formalities automatically to enhance overall business productivity.',
  },
  {
    icon: 'spriteIcon_11',
    title: 'HRM systems',
    description:
      'Saas human resource management systems we build are greatly customizable, offering clients flexible options for implementing functional or design changes.',
  },
  {
    icon: 'spriteIcon_13',
    title: 'Accounting apps',
    description:
      'We provide SaaS accounting software that is secure and allows easy collaboration across devices and locations.',
  },
  {
    icon: 'spriteIcon_14',
    title: 'Productivity apps',
    description:
      'Project management applications developed by Codcia offer powerful tools for effective project execution while promoting collaborative work.',
  },
]

export default SaaSSolutions
