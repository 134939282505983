import { getImage, StaticImage } from 'gatsby-plugin-image'

import { CommonImageProps } from '~/types/common-props'
import { WorksTabsContent } from '~/types/data-array'
import { fileToImageLikeData } from '~/utils'
import useSaasDevelopmentStaticQuery from '~/views/Services/SaasDevelopment/useSaasDevelopmentStaticQuery'

const photoClientCaker =
  '../../../assets/images/avatars/clients/client-caker.png'

const photoClientDV = '../../../assets/images/avatars/clients/client-dv.png'
const quoteCodicaPhotoSergey = '../../../assets/images/avatars/Sergey.png'

const imageProps: CommonImageProps = {
  width: 50,
  height: 50,
}

const SaaSDiscoverWorksTabContent = (): WorksTabsContent[] => {
  const query = useSaasDevelopmentStaticQuery()

  const portfolioCaker = getImage(fileToImageLikeData(query.portfolioCaker))
  const portfolioVaria = getImage(fileToImageLikeData(query.portfolioVaria))
  const portfolioDV = getImage(fileToImageLikeData(query.portfolioDV))

  const tabsContent = [
    {
      key: 'Bakery platform',
      gradient: 'gradientCaker',
      image: portfolioCaker,
      header: 'SaaS platform for CakerHQ',
      title:
        'CakerHQ is an Australian SaaS platform for bakery businesses we developed from scratch. It is intended to optimize business administration and simplify the process of finding and booking desserts for customers.',
      resultsLeft: 'User-friendly and attractive solution',
      resultsRight: 'Optimized for mobile and desktop devices',
      link: 'saas-platform-for-bakery-business',
      review: [
        '“I saw first hand the frustrations that the baking business owners faced in the running of their businesses. I surveyed and researched for a few years before deciding on developing CakerHQ in its current format to help these small business owners.',
        <div className="mb-2" key="descrOne" />,
        'Codica’s experts understood from the outset exactly what I was trying to achieve and worked with me to bring this vision to life.”',
      ],
      get clientPhoto() {
        return (
          <StaticImage
            src={photoClientCaker}
            alt={`${this.clientName} | Codica`}
            title={this.clientName}
            {...imageProps}
          />
        )
      },
      clientName: 'Lisa',
      clientPosition: 'Founder of CakerHQ',
    },
    {
      key: 'Rental solution',
      gradient: 'gradientVaria',
      image: portfolioVaria,
      header: 'Real estate online platform',
      title:
        'This project is a real estate SaaS platform that displays homeowners’ listings. The solution is responsive, fast-loading, and intuitive, allowing users to add images and 3D tours.',
      resultsLeft: 'Smooth loading on all devices',
      resultsRight: '3D tour feature to save users time',
      link: 'saas-real-estate-platform',
      review: [
        '“The task was to create a fast-loading and user-friendly SaaS for real estate. The SaaS needed to include features allowing users to view the real estate property and communicate with the homeowners.',
        <div className="mb-2" key="descrTwo" />,
        'Codica carefully approached the SaaS platform development and implemented the needed functionality. We put maximum effort and technical knowledge into achieving the goals set in the specification.”',
      ],
      get clientPhoto() {
        return (
          <StaticImage
            src={quoteCodicaPhotoSergey}
            alt={`${this.clientName} | Codica`}
            title={this.clientName}
            {...imageProps}
          />
        )
      },
      clientName: 'Serhii',
      clientPosition: 'Ruby on Rails Developer at Codica',
    },
    {
      key: 'Service marketplace',
      gradient: 'gradientDv',
      image: portfolioDV,
      header: 'Smart collaboration platform',
      title:
        'Digital Village is an Australian online service marketplace that brings together business owners and developers for fruitful collaboration and for making excellent software products.',
      resultsLeft: 'Clear UI and smooth UX design',
      resultsRight: 'Simple management and database administration',
      link: 'collaboration-marketplace-and-smart-contract-system',
      review:
        '“The Codica team have been terrific to work with. The ability to interpret business needs and apply them with little guidance has been of great value for our Startup.”',
      get clientPhoto() {
        return (
          <StaticImage
            src={photoClientDV}
            alt={`${this.clientName} | Codica`}
            title={this.clientName}
            {...imageProps}
          />
        )
      },
      clientName: 'Jason Hardie',
      clientPosition: 'Founder of The Digital Village Australia',
    },
  ]

  return tabsContent
}

export default SaaSDiscoverWorksTabContent
