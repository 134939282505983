import { ExpertiseSectionMock } from '~/types/mock'

const SaaSDevServices: ExpertiseSectionMock[] = [
  {
    icon: 'sprite3DIcon_10',
    title: 'SaaS consulting',
    description:
      'Our SaaS consultants help overcome the lack of expertise in SaaS development and transform your vision into a ready plan.',
  },
  {
    icon: 'sprite3DIcon_13',
    title: 'New SaaS product development',
    description:
      'We provide services to build SaaS solutions from scratch, from business needs analysis to SaaS deployment.',
  },
  {
    icon: 'sprite3DIcon_18',
    title: 'SaaS UX design',
    description:
      'Our SaaS user experience design services consider both user and business demands, delivering the greatest product value to end-users.',
  },
  {
    icon: 'sprite3DIcon_12',
    title: 'SaaS UI design',
    description:
      'User centric design for SaaS interfaces aims at making neat visual representations of SaaS projects’ functions.',
  },
  {
    icon: 'sprite3DIcon_17',
    title: 'SaaS architecture design',
    description:
      'In building SaaS architecture design, we always apply our best development skills and techniques to deliver usable and profitable products.',
  },
  {
    icon: 'sprite3DIcon_11',
    title: 'SaaS application development',
    description:
      'Experienced in a vast range of technologies, our engineers produce high-quality code to implement even your boldest ideas.',
  },
  {
    icon: 'sprite3DIcon_15',
    title: 'Tweaking an existing SaaS app',
    description:
      'We will upgrade your existing SaaS solution for a great onboarding experience and provide value for more users.',
  },
  {
    icon: 'sprite3DIcon_16',
    title: 'Integration with 3rd-party services',
    description:
      'Through maintaining third-party integrations, you can satisfy more specific needs of users and make them stay with you.',
  },
  {
    icon: 'sprite3DIcon_6',
    title: 'SaaS cloud application development and migrating',
    description:
      'Our SaaS development experts will help you migrate your SaaS solution to the cloud, making it scalable and globally available.',
  },
  {
    icon: 'sprite3DIcon_19',
    title: 'SaaS testing',
    description:
      'Our SaaS testers ensure we implement a solution that works and generates the best results for your business.',
  },
  {
    icon: 'sprite3DIcon_20',
    title: 'Maintenance and support',
    description:
      'We provide a broad range of support and maintenance services to ensure your product works effectively after the launch.',
  },
]

export default SaaSDevServices
