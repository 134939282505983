import { FAQ } from '~/types/data-array'

const SaaSFAQ: FAQ[] = [
  {
    question: 'How much does it cost to develop a SaaS?',
    answer:
      'The average cost of creating a SaaS app will be between $50,000-$150,000. The price can vary depending on the app scope, API integration, features, the development platform, and the engineers’ rates.',
  },
  {
    question: 'How is the development of SaaS related to cloud computing?',
    answer:
      'Software as a service is a sub-service of cloud computing as cloud app creation enables running many SaaS solutions.',
  },
  {
    question: 'What is SaaS architecture?',
    answer:
      'SaaS architecture means a method of software delivery. SaaS architecture is unique but can be divided into the industry (horizontal, vertical) and tenancy model (single-tenant, multi-tenant, or mixed-tenant) categories.',
  },
  {
    question: 'What is SaaS application development?',
    answer:
      'This means designing and coding apps that are delivered and used over the World Wide Web. Instead of installing and maintaining software products, you access SaaS solutions online. Thus, you avoid complex software and hardware management.',
  },
  {
    question: 'What is SaaS in web development?',
    answer:
      'SaaS is a way of software delivery that enables users to access data from any device via the Internet and a web browser.',
  },
  {
    question: 'Why to use SaaS?',
    answer:
      'SaaS can be used via the Internet 24/7 from any device. You don’t need equipment updates, installation, or traditional licensing management. Also, you don’t need to pay upfront hardware expenses. With SaaS, you use flexible payment options such as pay-as-you-go models.',
  },
]

export default SaaSFAQ
