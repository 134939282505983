import { RequirementsSoftwareMock } from '~/types/mock'

const SaaSRequirements: RequirementsSoftwareMock[] = [
  {
    icon: 'spriteIcon_56',
    title: 'User-friendly',
    list: [
      { item: 'Easy navigation' },
      { item: 'Clean design' },
      { item: 'Responsive' },
    ],
  },
  {
    icon: 'spriteIcon_20',
    title: 'PWA by default',
    list: [
      { item: 'Mobile-friendly' },
      { item: 'Connectivity independent' },
      { item: 'Discoverable' },
    ],
  },
  {
    icon: 'spriteIcon_60',
    title: 'Engaging',
    list: [
      { item: 'Installable' },
      { item: 'Push notifications' },
      { item: 'Conversion optimized' },
      { item: 'Tracking pixels enabled' },
    ],
  },
  {
    icon: 'spriteIcon_15',
    title: 'Fast-loading',
    list: [
      { item: 'Open in < 1 second on mobile' },
      { item: 'Prefetch soon-to-be-needed resources' },
    ],
  },
  {
    icon: 'spriteIcon_37',
    title: 'Secure',
    list: [
      { item: 'Safe: served via the TLS protocol to prevent snooping' },
      { item: 'Free from vulnerabilities' },
    ],
  },
  {
    icon: 'spriteIcon_91',
    title: 'Scalable',
    list: [
      {
        item: (
          <>
            Follow
            <a
              href="https://web.dev/apply-instant-loading-with-prpl/"
              target="_blank"
              className="mx5"
              key="linkPRPL"
            >
              PRPL Pattern
            </a>
            (Push, Render, Pre-cache, Lazy-load)
          </>
        ),
      },
      {
        item: 'Flexible SaaS environment',
      },
    ],
  },
]

export default SaaSRequirements
